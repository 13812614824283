.btn {
  display: block; 
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: #E10500;
  background-clip: padding-box;
  border: 1px solid #E10500;  
  border-radius: 10px;
  width: 100%;
  text-align: center;  
  padding: 10px;
  height:70px
}
.btn:hover {
  background-color: #ab2424;
  border-color: #ab2424;
}
.btn:disabled {
  background-color: #A3A3B2;
  border: 1px solid #A3A3B2;
}