.legend {
  width: 40px;
  height: 3px;
  margin-right: 5px;
  position: relative;
}
.legendWrapperNew {
  width: 49px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.reachLegend{
  position: absolute;
  top: 89%;
  left: 27.5%
 }
 .reachIncrLegend {
  position: absolute;
  top: 89%;
  left: 40.5%
 }
 .CPRPIncrLegend {
  position: absolute;
  top: 89%;
  left: 56.5% 
 }
@media screen and (max-width: 1600px) {
  .wrapper { zoom: 125%; }
}

