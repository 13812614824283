.wrapper {
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
font-family: Tahoma;
}
.parameter {
  font-size: 1.3rem;
  font-family: Tahoma;
}
.parameterValue {
  font-size: 1.8rem;
  font-weight: bold;
  font-family: Tahoma;
}
.border {
  border-bottom: 1px solid #E5E5E5;
}