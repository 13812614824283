.wrapper {
    width: 100%;
    background-color: white;
}
.content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 600px;  
}
.arrowBlock {
    width: 5%;
    height: 100%;
    background-color: #D1E2ED;
    position: relative;
}
.detailBlock {
    width:65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0.2rem 0 0.2rem rgb(0 0 0 / 20%);
    position: relative;
    margin-left: 10px;
}
.arrowRight {
    position: absolute;
    top: 43%;
    left: 97.5%;
    width: 52px;
    height: 100px;
}
.chartBlock {
    width:30%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.detailKpi {
    width: 90%;
    display: flex;
    flex-direction: column;
}
.title {
    font-size: 1.8rem;
    font-weight: bold;
    font-family: Tahoma;
    margin-bottom: 10px;
}
.detailBtn {
    position: absolute;
    bottom:-13%;
    left: 5%;
}
.exportPptBtn {
    position: absolute;
    bottom:-13%;
    left: 46%;
}
.exportExelBtn {
    position: absolute;
    bottom:-13%;
    left: 26%;
}
@media screen and (max-width: 1600px) {
    .wrapper { zoom: 80%; }
}