.warningCard {
  max-height: 250px;
  overflow: auto;
  background-color: #e10500;
  color: white;
  font-size: 18px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  width: 30vw;
  margin-bottom: 40px;
  padding: 10px;
  z-index: 12000;
  text-overflow: initial;
  text-align: center;
  vertical-align: middle;
}
.warning {
  background-color: rgb(250, 250, 250, 0.4);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 10000;
}
.active {
  opacity: 1;
  pointer-events: all;
}
.info {
  background-color: orange;
  color: grey;
}
