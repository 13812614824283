.wrapper {
    width: 100%;
    background-image: url(../../../assets//images//backPic.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
}
.btnsBlock {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}
.centerBlock {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.title {
    color: #262626;
    font-size: 3rem;
    font-weight: 800;
    position: relative;    
}
.title:after {
    content: url(../../../assets//images//Picture4.png);
    position: absolute;
    top: 1%;
    right: -10%;
}
.title:before{
    content: url(../../../assets//images//star.png);
    position: absolute;
    top: -40%;
    left: -23%;
  }