.wrapper {
  width: 100%;
  background-image: url(../../../assets//images//backPic.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.form {
  width: 70%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.centerBlock {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.title {
  color: #262626;
  font-size: 3rem;
  font-weight: 800;
  position: relative;
}
.title:after {
  content: url(../../../assets//images//Picture4.png);
  position: absolute;
  top: 1%;
  right: -10%;
}
.title:before {
  content: url(../../../assets//images//star.png);
  position: absolute;
  top: -40%;
  left: -23%;
}
.lock {
  width: 20px;
  height: 20px;
}
.formInfoTextWrapper {
  display: flex;
}
a {
  text-decoration: none;
  color: #595959;
  font-weight: bold;
}
.linkDisabled {
  pointer-events: none;
}
