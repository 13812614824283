.span {
  display: block;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 2px 2px rgb(128 128 128 / 30%);
  width: 52px;
  min-height: 25px;
  text-align: center;
}
.input {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 8px;
  outline: 0;
  box-shadow: 0 0 2px 2px rgb(128 128 128 / 30%);
  text-align: center;
  width: 52px;
  min-height: 25px;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.input:focus {
  background-color: #fff;
  border-color: #e99898;
  border-radius: 8px;
  outline: 0;
  box-shadow: 0 0 2px 2px rgb(128 128 128 / 30%);
  text-align: center;
  width: 52px;
  min-height: 25px;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
.disabled {
  box-shadow: none;
  pointer-events: none;
  color: grey;
}
