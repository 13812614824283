.btnWrapper {
  background-color: white;
  height: 250px;
  width: 250px; 
  border: none;
  color: black;
  font-size: 1.3rem; 
  white-space: pre;
  box-shadow: 0 0 20px 20px rgba(128, 128, 128, 0.2);
  display: flex;
  flex-direction: column;
  justify-content:space-evenly; 
  align-items: center;
  border-radius: 50px;
  line-height: 2rem;
  text-align: center;
  line-height: 1.5;
  font-family: Tahoma;
  position: relative;
}
.btn {
  background-color: #FF5854;
  height: 40px;
  width: 150px; 
  border: none;
  color: white;
  font-size: 1.2rem; 
  font-weight: bold;
  white-space: pre;
  box-shadow: 0 0 2px 2px rgba(128, 128, 128, 0.5);
  display: flex;
  flex-direction: column;
  justify-content:space-evenly; 
  align-items: center;
  border-radius: 50px;
  line-height: 2rem;
  position:absolute;
  bottom: 10%
}
.btn:hover {
  box-shadow: 0 0 20px 5px rgba(255, 5, 0, 0.3);
  background-color: rgb(225, 5, 0);
}
.btn:disabled {
  background-color: rgb(237, 237, 238);
  border-color: rgb(237, 237, 238);
}
.titleWrapper {
  position:absolute;
  top: 30%
}
.imgWrapper {
  position:absolute;
  top: 10%
}