.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  min-height: 210px;
  max-height: 550px;
  min-width: 450px;
  background-color: white;
  color: black;
  font-size: 18px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 20%);
  position: absolute;
  top: 40%;
  z-index: 999;
  overflow: auto;
}
.modal {
  background-color: rgba(0, 0, 0, 0.432);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 999;
}
.active {
  opacity: 1;
  pointer-events: all;
  width: 100%;
  height: 100%;
}
.titleBlock {
  display: flex;
  justify-content: center;
}
.btnBlock {
  width: 100%;
  display: flex;
  justify-content: end;
}
.closeButton {
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 25px;
}
.selectWrapper {
  width: 400px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn {
  background-color: #ff5854;
  height: 40px;
  width: 150px;
  border: none;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: pre;
  box-shadow: 0 0 2px 2px rgba(128, 128, 128, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 50px;
  line-height: 2rem;
  bottom: 10%;
  margin: 5px;
}
.btn:hover {
  box-shadow: 0 0 20px 5px rgba(255, 5, 0, 0.3);
  background-color: rgb(225, 5, 0);
}
.btn:disabled {
  background-color: rgb(237, 237, 238);
  border-color: rgb(237, 237, 238);
  pointer-events: none;
  color: grey;
}
.btnUploadBlock {
  display: flex;
  justify-content: center;
}
/* 
@media screen and (max-width: 1500px) {
  .optimizationCard {  max-height: 800px; }
} */
