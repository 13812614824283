.select {
  position: relative;
  display: block;
  width: 100%;
}
.select__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  font-family: Tahoma;
  background-clip: padding-box;
  border: 1px solid #a3a3b2;
  border-radius: 10px;
  height: 50px;
  padding: 10px;
  min-width: 100%;
  background-color: white;
}
.select__list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-family: Tahoma;
  font-size: 1rem;
  color: black;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  max-height: 370px;
  overflow-y: auto;
  width: 107%;
  text-align: center;
}
.select__list .select__item {
  position: relative;
  padding: 3px 10px;
  list-style-type: none;
}
.select__list .select__item:hover {
  background-color: #c4c4c4;
}
.disabled {
  box-shadow: none;
  pointer-events: none;
}
.img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 55%;
  right: 0;
}
.placeholderText {
  color: black;
  font-weight: bold;
  font-family: Tahoma;
}
