.btn {
  background: linear-gradient(0deg, #214962 0%, #143041 100%);
  height: 45px;
  width: 160px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  white-space: pre;
  box-shadow: 0 0 2px 2px rgba(128, 128, 128, 0.5);
  border-radius: 50px;
  font-family: Tahoma;
}
.btn:hover {
  background: linear-gradient(0deg, #2a76a4 0%, #174867 100%);
}
.btn:disabled {
  background: none;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}

.exportBtn {
  background: linear-gradient(0deg, #748d9e 0%, #43525d 100%);
  height: 45px;
  width: 160px;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  white-space: pre;
  box-shadow: 0 0 2px 2px rgba(128, 128, 128, 0.5);
  border-radius: 50px;
  font-family: Tahoma;
}
.exportBtn:hover {
  background: linear-gradient(0deg, #597e96 0%, #2c4656 100%);
}
.exportBtn:disabled {
  background: none;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
