.wrapper {
  position: relative;
}
.head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  font-family: Tahoma;
  background-clip: padding-box;
  border: 1px solid #a3a3b2;
  border-radius: 10px;
  height: 50px;
  padding: 10px;
  min-width: 100%;
  background-color: white;
}
.details {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-family: Tahoma;
  font-size: 1rem;
  color: black;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  max-height: 370px;
  overflow-y: auto;
  width: 107%;
  text-align: center;
}
.detailsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.colWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.placeholderText {
  color: black;
  font-weight: bold;
  font-family: Tahoma;
}

.meanChromo {
  border: 2px solid grey;
  border-radius: 30px;
  padding: 0.1rem 1.1rem;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 110px;
  margin: 10px;
}
.col {
  display: flex;
  flex-direction: column;
  width: 140px;
}
.img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 55%;
  right: 0;
}
.applyBtnWrapper {
  margin-bottom: 10px;
}
